import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../../components/Layout"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Nav, Card, CardImg, CardProps, CardImgProps } from "react-bootstrap"
import Prodopt from "../../../components/Prodopt"
import Prodfeat from "../../../components/Prodfeat"



const FDP = () => (
    <Layout>
        <Container>
            <div className="column">
                <br /><br />
                <div class="text-center">
                    {/* Картинка */}
                    <StaticImage
                        src="img/cgm2.png"
                        width={790}
                        quality={50}
                        placeholder="blurred"
                        formats={["auto", "webp", "avif"]}
                        alt="CGM"
                    />
                </div>
                {/* Заголовок */}
                <h1 class="text-center py-3">
                CGM РАБОЧАЯ ГЛУБИНА 45 С ГИДРАВЛИЧЕСКИМ ДВОЙНЫМ КАТКОМ.
                </h1>
                {/* Текст */}
                <p class="blockquote card px-4 mx-5 py-3">
                Глубокорыхлители Ozdoken CGM предназначены для глубокого разрыхления грунта с целью разрушения «плужной подошвы» на глубину до 45 см.
Данные модели предназначены для тракторов средней мощности (110-200л.с.) и характеризуются максимальной рабочей глубиной 45см и являются идеальным решением для малых и средних фермерских хозяйств. Машины предлагаются в версиях с 5 или 7 стойками, которые оснащены системой защиты со срезными болтами, а двойной задний прикатывающий каток можно регулировать с помощью гидравлики.
                </p>

                <h2 class="text-center py-3 text-black-50">
                    ПРЕИМУЩЕСТВА ПРОДУКТА
                </h2>

                <div class="row justify-content-center text-center px-5">
                    <Prodfeat text="Глубокое разрыхление почвы" />
                    <Prodfeat text="Эффективное рыхление и аэрация" />
                    <Prodfeat text="Остатки заделываются в почву" />
                    <Prodfeat text="Идеально выровненная почва" />

                </div>

                {/* Преимущества */}
                <h2 class="text-center pt-3 text-black-50">
                    ОБОРУДОВАНИЕ ПРОДУКТА
                </h2>
                <div class="row px-3">
                </div>

                <h2 class="text-center py-3 text-black-50">
                    ТЕХНИЧЕСКИЕ СПЕЦИФИКАЦИИ
                </h2>
                {/* Таблица */}
                <div class="table-responsive">
                    <table class="table table-striped table-hover table-bordered">
                    <thead class="background-dark-gray-ozdo">
                    <tr>
		<td height="47"><b>Модель</b></td>
		<td>Количество&nbsp;лап</td>
		<td>Глубина обработки, см</td>
		<td>Рабочая ширина, м</td>
		<td>Транспортная ширина, м</td>
		<td>Финишное орудие</td>
		<td>Регулировка&nbsp;глубины</td>
	</tr>
    </thead><tbody class="align-middle">
	<tr>
		<td height="17" align="center"><b>CGM-5</b></td>
		<td align="center">5</td>
		<td align="center">45</td>
		<td align="center">2,4</td>
		<td align="center">2,4</td>
		<td rowspan="2" align="center">двойной зубчатый каток</td>
		<td rowspan="2" align="center">гидравлическая</td>
	</tr>
	<tr>
		<td height="17" align="center"><b>CGM-7</b></td>
		<td align="center">7</td>
		<td align="center">45</td>
		<td align="center">3</td>
		<td align="center">3</td>
		</tr>
        </tbody>
                    </table>
                </div>
            </div>
        </Container>
    </Layout>
);

export default FDP;